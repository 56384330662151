import React from "react"
import { EmailForm } from "../components/EmailForm"

export const Contact = () => {
  return (
    <>
      <div className="form-container">
        <EmailForm />
      </div>
    </>
  )
}
